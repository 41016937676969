import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Import SVGs the same way as StarIcon
import MinusIcon from '../../assets/images/icon-minus.svg';
import PlusIcon from '../../assets/images/icon-plus.svg';

interface faqProps {
    title: string;
    text: string;
}

// Styled SVG Icons
const BrighterIcon = styled.div`
  svg {
    filter: brightness(4); // Adjust the value as needed
  }
`;

const FAQQuestion = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  border-bottom: 1px solid hsl(275, 100%, 97%);
  &:hover {
    h2 {
      color: #ad28eb;
    }
  }
  ${({ isActive }) => isActive && css`
    p {
      display: block;
    }
  `}
  @media only screen and (max-width: 285px) {
  h2 {
    font-size: 17px;
  }
}
`;

const Title = styled.h2`
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  font-weight: 700;
`;

const Text = styled.p`
  margin: 0 0 1.5rem 0;
  font-size: 15px;
  color: hsl(292, 16%, 49%);
  display: none;
  line-height: 26px;
  font-weight: 400;
`;

export default function Question({ title, text }: faqProps) {
    const [isActive, setIsActive] = useState(false);

    const toggleIsActive = () => setIsActive(prev => !prev);

    return (
        <FAQQuestion onClick={toggleIsActive} isActive={isActive}>
            <Title>
                {title}
                {isActive ? <BrighterIcon><MinusIcon /></BrighterIcon> : <BrighterIcon><PlusIcon /></BrighterIcon>}
            </Title>
            <Text>{text}</Text>
        </FAQQuestion>
    );
}

