import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TextLogo from 'assets/svg/brand/text-logo-white.svg'
import { FlexDivColCentered } from 'components/layout/flex'
import { Body } from 'components/Text'
import { EXTERNAL_LINKS } from 'constants/links'
import { ExternalLink } from 'styles/common'

const PoweredBySynthetix = () => {
	const { t } = useTranslation();
	return (
		<Container>
			<TextAndLogoWrapper>
				<Text>{t('common.powered-by')}</Text>
				<ExternalLink href={''}>
					<TextLogo height="11.5px" style={{ marginLeft: '5px' }} />
				</ExternalLink>
			</TextAndLogoWrapper>
		</Container>
	);
};

const Container = styled(FlexDivColCentered)`
	align-items: center;
	justify-content: center;
	width: 100%; // Ensure Container is centered within its parent
`;

const TextAndLogoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%; // Make sure the wrapper takes full width of its container
	position: relative;
`;

const Text = styled(Body)`
	font-size: 10px;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	opacity: 0.5;
	letter-spacing: 0.04em;
	font-variant: small-caps;
	text-transform: uppercase;
	line-height: 100%;
	text-align: center;
	display: flex;
	align-items: center;
`;
export default PoweredBySynthetix
