import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import GridSvg from 'assets/svg/app/grid.svg'
import Button from 'components/Button'
import Currency from 'components/Currency'
import { FlexDivColCentered, FlexDivRow } from 'components/layout/flex'
import Loader from 'components/Loader'
import { MobileOnlyView, NotMobileView } from 'components/Media'
import Table, { TableHeader } from 'components/Table'
import { Body } from 'components/Text'
import ROUTES from 'constants/routes'
import { StackSection } from 'sections/homepage/section'
import { Title } from 'sections/homepage/text'
import { SmallGoldenHeader, WhiteHeader } from 'styles/common'
import media from 'styles/media'

import { requestShortList } from '../../queries/rates/useCandlesticksQuery1'

// Define the type for your stat items
type StatItem = {
	Name: string
	timestart: number
	confidence_interval: string
	highest_PL: string
	retrace_range: string
	profit_rank: number
	performance: string
}

type TotalTradeStats = {
	total: number
	days: number
	average: number
}

const getMedal = (position: number) => {
	switch (position) {
		case 1:
			return <Medal>🥇</Medal>
		case 2:
			return <Medal>🥈</Medal>
		case 3:
			return <Medal>🥉</Medal>
		default:
			return <Medal> {position} </Medal>
	}
}

const ShortList = () => {
	const { t } = useTranslation()
	const [stat, setStat] = useState<StatItem[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [totalTradeStats, setTotalTradeStats] = useState<TotalTradeStats | null>(null)

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true) // Set loading to true before fetching
			try {
				const data = await requestShortList() // Await the fetched data
				setStat(data) // Update stat state with the fetched data
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Failed to fetch data:', error)
			} finally {
				setLoading(false) // Ensure loading is set to false after fetching
			}
		}

		fetchData()
	}, []) // Empty dependency array means this effect runs once on mount

	useEffect(() => {
		if (stat.length > 0) {
			// Ensure we have data to work with
			const total = stat.length
			const earliestUnixtime = Math.min(...stat.map((item) => item.timestart))
			const days = (Date.now() / 1000 - earliestUnixtime) / (60 * 60 * 24)
			const averageHighestPL =
				stat.reduce((acc, item) => {
					const plNumber = parseFloat(item.highest_PL)
					return acc + (isNaN(plNumber) ? 0 : plNumber) // Skip or treat as 0 if not a valid number
				}, 0) / total
			setTotalTradeStats({
				total,
				days: Math.floor(days), // Whole days
				average: parseFloat(averageHighestPL.toFixed(2)), // Two decimal precision
			})
		}
	}, [stat]) // Dependency on stat ensures this runs after stat is set
	const router = useRouter()

	const onClickTrader = useCallback(
		(trader: string) => {
			router.push(ROUTES.Leaderboard.Trader(trader))
		},
		[router]
	)

	const title = (
		<>
			<SmallGoldenHeader>{t('homepage.shortlist.title')}</SmallGoldenHeader>
			<WhiteHeader>{t('homepage.shortlist.description')}</WhiteHeader>
		</>
	)

	const sectionTitle = (
		<>
			<SectionFeatureTitle>{t('homepage.shortlist.stats.title')}</SectionFeatureTitle>
		</>
	)

	return (
		<StackSection>
			<Container>
				<FlexDivColCentered>{title}</FlexDivColCentered>
				<NotMobileView>
					<StyledTable
						isLoading={loading}
						onTableRowClick={(row) => onClickTrader(row.original.performance)}
						data={stat}
						showPagination={true}
						showShortList={true}
						pageSize={10}
						hideHeaders={false}
						highlightRowsOnHover
						columns={[
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.profit-rank')}</TableHeader>
								),
								accessorKey: 'profit_rank',
								cell: (cellProps) => (
									<StyledOrderType>{getMedal(cellProps.row.original.profit_rank)}</StyledOrderType>
								),
								size: 60,
							},
							{
								header: () => <TableHeader>{t('leaderboard.leaderboard.table.rank')}</TableHeader>,
								accessorKey: 'timestart',
								cell: (cellProps) => (
									<Body size="large">
										{new Date(cellProps.row.original.timestart * 1000).toLocaleString()}
									</Body>
								),
								size: 125,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.trader')}</TableHeader>
								),
								accessorKey: 'Name',
								cell: (cellProps) => <Body size="large">{cellProps.row.original.Name}</Body>,
								size: 60,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.total-trades')}</TableHeader>
								),
								accessorKey: 'confidence_interval',
								cell: (cellProps) => (
									<Body size="large">{cellProps.row.original.confidence_interval}</Body>
								),
								size: 100,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.liquidations')}</TableHeader>
								),
								accessorKey: 'retrace_range',
								cell: (cellProps) => (
									<Body size="large">{cellProps.row.original.retrace_range}</Body>
								),
								size: 100,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.total-pnl')}</TableHeader>
								),
								accessorKey: 'highest_PL',
								cell: (cellProps) => <Body size="large">{cellProps.row.original.highest_PL}</Body>,
								size: 60,
							},
						]}
					/>
				</NotMobileView>
				<MobileOnlyView>
					<StyledTable
						isLoading={loading}
						onTableRowClick={(row) => onClickTrader(row.original.performance)}
						data={stat}
						pageSize={5}
						hideHeaders={false}
						highlightRowsOnHover
						columns={[
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.profit-rank')}</TableHeader>
								),
								accessorKey: 'profit_rank',
								cell: (cellProps) => (
									<StyledOrderType>{getMedal(cellProps.row.original.profit_rank)}</StyledOrderType>
								),
								size: 45,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.trader')}</TableHeader>
								),
								accessorKey: 'Name',
								cell: (cellProps) => {
									return <Body>{cellProps.row.original.Name}</Body>
								},
								size: 90,
							},
							{
								header: () => (
									<TableHeader>{t('leaderboard.leaderboard.table.total-pnl')}</TableHeader>
								),
								accessorKey: 'pnl',
								cell: (cellProps) => <Body size="large">{cellProps.row.original.highest_PL}</Body>,
								size: 120,
							},
						]}
					/>
				</MobileOnlyView>
				<FlexDivColCentered>{sectionTitle}</FlexDivColCentered>
				<StatsCardContainer>
					<StatsCard>
						<StatsValue>{loading ? <Loader /> : totalTradeStats?.total || '0'}</StatsValue>
						<StatsName>{t('homepage.shortlist.stats.volume')}</StatsName>
						<GridSvg />
					</StatsCard>
					<StatsCard>
						<StatsValue>{loading ? <Loader /> : totalTradeStats?.average ?? 0} %</StatsValue>
						<StatsName>{t('homepage.shortlist.stats.trades')}</StatsName>
						<GridSvg />
					</StatsCard>
					<StatsCard>
						<StatsValue>{loading ? <Loader /> : totalTradeStats?.days ?? 0}</StatsValue>
						<StatsName>{t('homepage.shortlist.stats.traders')}</StatsName>
						<GridSvg />
					</StatsCard>
				</StatsCardContainer>
			</Container>
		</StackSection>
	)
}

const StatsName = styled.div`
	font-size: 15px;
	color: ${(props) => props.theme.colors.common.secondaryGray};
`

const StatsValue = styled.div`
	font-size: 32px;
	color: ${(props) => props.theme.colors.selectedTheme.white};
`

const StatsCardContainer = styled(FlexDivRow)`
	margin-top: 40px;
	justify-content: center;
	column-gap: 20px;

	${media.lessThan('lgUp')`
		flex-wrap: wrap;
		row-gap: 20px;
	`}

	${media.lessThan('sm')`
		flex-direction: column;
		margin: auto;
		padding: 0px;
		row-gap: 15px;
		margin-top: 30px;
	`}
`

const StatsCard = styled(Button)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 291px;
	height: 191px;
	font-family: ${(props) => props.theme.fonts.regular};
	border-radius: 15px;
	justify-content: center;
	&::before {
		border-radius: 15px;
	}
	cursor: default;
	transition: all 1s ease-in-out;

	&:hover {
		background: linear-gradient(180deg, rgba(40, 39, 39, 0.5) 0%, rgba(25, 24, 24, 0.5) 100%);
	}

	background: linear-gradient(180deg, rgba(40, 39, 39, 0.5) 0%, rgba(25, 24, 24, 0.5) 100%);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.1),
		inset 0px 0px 20px rgba(255, 255, 255, 0.03);
	svg {
		width: 291px;
		height: 75px;
		position: absolute;
		right: 0;
		top: 0;

		margin-top: 115px;
		z-index: 20;
		background-size: cover;
	}
`

const StyledTable = styled(Table)`
	margin-top: 60px;
	font-size: 15px;
	width: 1160px;
	background: #131212;

	${media.lessThan('lgUp')`
		width: 720px;
	`}

	${media.lessThan('sm')`
		width: 345px;
		& > .table-body >.table-body-row >.table-body-cell {
			padding-left: 0px;
		}
	`};
` as typeof Table

const Medal = styled.span`
	font-size: 15px;
`

const ColorCodedPrice = styled(Currency.Price)`
	align-items: right;
	color: ${(props) =>
		props.price > 0
			? props.theme.colors.green
			: props.price < 0
			? props.theme.colors.red
			: props.theme.colors.white};
	font-size: 15px;
`

const Container = styled(FlexDivColCentered)`
	padding-bottom: 140px;
	justify-content: center;
`

const StyledOrderType = styled.div`
	color: ${(props) => props.theme.colors.white};
	text-align: center;
	width: 45px;
`

const FeatureTitle = styled(Title)`
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 22px;
	font-variant: all-small-caps;
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	width: 150px;
	letter-spacing: 2px;
`

const SectionFeatureTitle = styled(FeatureTitle)`
	font-size: 20px;
	line-height: 100%;
	font-family: ${(props) => props.theme.fonts.black};
	font-variant: all-small-caps;
	letter-spacing: 0px;
	margin-top: 100px;
	text-align: center;
	width: auto;
	${media.lessThan('sm')`
		width: 100vw;
	`}
`

export default ShortList
