import { FC } from 'react'
import { RefObject } from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import { FullScreenContainer } from 'styles/common'
import { themes } from 'styles/theme'
import darkTheme from 'styles/theme/colors/dark'

import Background from './Background'
import Footer from './Footer'
import Header from './Header'
type HomeLayoutProps = {
	children: React.ReactNode
	scrollToAssetsRef: RefObject<HTMLDivElement>
	scrollToShortListRef: RefObject<HTMLDivElement>
	scrollToFeaturesRef: RefObject<HTMLDivElement>
	scrollToFaqRef: RefObject<HTMLDivElement>
}

const HomeLayout: FC<HomeLayoutProps> = ({
	children,
	scrollToAssetsRef,
	scrollToShortListRef,
	scrollToFeaturesRef,
											 scrollToFaqRef,
}) => (
	<ThemeProvider theme={themes.dark}>
		<Background />
		<ScreenContainer>
			<GlobalStyle />
			<Header
				scrollToAssetsRef={scrollToAssetsRef}
				scrollToShortListRef={scrollToShortListRef}
				scrollToFeaturesRef={scrollToFeaturesRef}
				scrollToFaqRef={scrollToFaqRef}
			/>
			{children}
			<Footer />
		</ScreenContainer>
	</ThemeProvider>
)

const GlobalStyle = createGlobalStyle`
	body {
		overflow-x: hidden;
		background-color: ${darkTheme.background};
		color: ${darkTheme.text.value};
	}
`

const ScreenContainer = styled(FullScreenContainer)`
	overflow-y: scroll;
`

export default HomeLayout
