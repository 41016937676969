import React from 'react';
import styled from 'styled-components';
import Question from '../Question';
import { faqData } from '../../data/faqData';
import StarIcon from '../../assets/images/icon-star.svg';
import {GridDiv} from "../layout/grid";
import { SmallGoldenHeader, WhiteHeader } from 'styles/common'
import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { FlexDiv, FlexDivColCentered, FlexDivRow } from 'components/layout/flex'


// Defining the color variables as constants
const colors = {
    white: 'hsl(0, 0%, 100%)',
    black: '#000', // Added black color
    lightPink: 'hsl(275, 100%, 97%)',
    grayishPurple: 'hsl(292, 16%, 49%)',
    brightPurple: '#ad28eb',
    darkPurple: 'hsl(292, 42%, 14%)',
};

// Styled components
const FaqSection = styled.section`
  position: relative;
  background-color: transparent;
  width: 100%;
  max-width: 600px;
  margin: 25px auto 0; // Added top margin, auto horizontal, and 0 bottom
  top: -100px;
  padding: 3rem 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 42px;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: ${colors.darkPurple}; // Applying color to h1 here

    img {
      filter: brightness(0); // Adjust if needed to fit the icon styling
    }
  }

  @media only screen and (max-width: 285px) {
    padding: 2.5rem 1.5rem;

    h1 {
      font-size: 38px;
    }
  }
`;

const Container = styled(GridDiv)`
	width: 100vw;
	overflow: hidden;
	justify-content: center;
	padding: 110px 0px;
`



// Component Definition
export default function Faq() {
    const { t } = useTranslation()
    const title = (
        <>
            <SmallGoldenHeader>{t('homepage.faq.title')}</SmallGoldenHeader>
            <WhiteHeader>{t('homepage.faq.description')}</WhiteHeader>
        </>
    )
    return (
        <Container>
            <FlexDivColCentered>{title}</FlexDivColCentered>
        <FaqSection>
            <h1> </h1>
            {/* Questions */}
            {faqData.map((faqSingle) => (
                <Question key={faqSingle.id} title={faqSingle.title} text={faqSingle.text} />
            ))}
        </FaqSection>
        </Container>
    );
}
