import dynamic from 'next/dynamic'
import Head from 'next/head'
import { FC } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Hero from 'sections/homepage/Hero'
import ShortList from 'sections/homepage/ShortList'
import HomeLayout from 'sections/shared/Layout/HomeLayout'
import media from 'styles/media'
import Features from '../sections/homepage/Features'
import TermsModal from '../components/TermsModal';
import React, { useEffect, useState } from 'react';
type AppLayoutProps = {
	children: React.ReactNode
}
import Faq from '../components/Faq'

type HomePageComponent = FC & { layout?: FC<AppLayoutProps> }

const Assets = dynamic(() => import('../sections/homepage/Assets2'), {
	ssr: false,
})

const HomePage: HomePageComponent = () => {
	const { t } = useTranslation()

	const [modalIsOpen, setModalIsOpen] = useState(false);
	useEffect(() => {
		const hasAgreedToTerms = localStorage.getItem('agreedToTerms');
		if (!hasAgreedToTerms) {
			setModalIsOpen(true);
		}
	}, []);
	const handleCloseModal = () => {
		localStorage.setItem('agreedToTerms', 'true');
		setModalIsOpen(false);
	};

	const assetsRef = useRef<HTMLDivElement>(null)
	const shortListRef = useRef<HTMLDivElement>(null)
	const adRef = useRef<HTMLDivElement>(null)
	const faqRef = useRef<HTMLDivElement>(null)

	return (
		<>
			<Head>
				<title>{t('homepage.page-title')}</title>
			</Head>
			<HomeLayout
				scrollToAssetsRef={assetsRef}
				scrollToShortListRef={shortListRef}
				scrollToFeaturesRef={adRef}
				scrollToFaqRef={faqRef}
			>
				<Container>
					<Hero scrollToAssetsRef={assetsRef} />
					<div ref={assetsRef}>
						<Assets />
					</div>
					<div ref={shortListRef}>
						<ShortList />
					</div>
					<div ref={adRef}>
						<Features />
					</div>
					<div ref={faqRef}>
						<Faq />
					</div>
				</Container>
			</HomeLayout>
		</>
	)
}

export const Container = styled.div`
	width: 100%;
	margin: 0 auto;
	padding: 100px 20px 0 20px;
	${media.lessThan('sm')`
		padding: 50px 15px 0 15px;
	`}
`

export default HomePage
