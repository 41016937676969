export const faqData = [
    {
        id: 1,
        title: "What is Moonshot Picks, and how can it benefit me?",
        text: `Moonshot Picks are selected daily by our finely tuned AI model. If the AI predicts an asset will rise by at least 10% within the next 8 hours, the asset gets listed on our platform. However, it's important to pay attention to the confidence level associated with each asset to find the safest entry point. For example, if there's a 50% confidence level for the asset to trade at $2 and a 100% confidence level for it to trade at $1, you are more likely to achieve a profit with less risk at the $1 price level. Always aim to trade at a higher confidence level for an asset. 😊`},

    {
        id: 2,
        title: "Can I rely on the confidence levels provided?",
        text: `The AI model is trained on the historical data of each asset and makes its decisions based on the past performance of a token. It is precise and unemotional, always providing users with statistically sound analysis. Past performance is also displayed on the platform, showing the number of Moonshot Picks in real time and their average profit within 8 hours, allowing users to assess the model's effectiveness. However, the confidence level represents only the AI's opinion. Your judgment should take precedence when making trading decisions.`
    },

    {
        id: 3,
        title: "Where can I trade Moonshot Picks?",
        text: `Moonshot Picks can be traded across a variety of platforms, depending on the specific assets our AI selects. Typically, these assets are accessible on major cryptocurrency exchanges and trading platforms, including Binance and OKX. We recommend visiting CoinMarketCap for direct links to the exchanges where each Moonshot Pick can be traded. It's important to have an account on these exchanges and to conduct your own research to ensure safe and effective trading.`
    },

    {
        id: 4,
        title: "Will ToDaMoon continue to be free of charge?",
        text: `ToDaMoon is free of charge, and we aim to keep basic features accessible at no cost to our users. However, as we continue to improve and expand our platform, certain advanced features or premium services may be introduced with a cost in the future.`
    }
]; 